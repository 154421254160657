import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

function ViewUser() {
  const [user, setuser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams()


  useEffect(() => {
    // Récupérer les données depuis l'API
    axios
      .get(`https://ishure-abarundi.webserver.control.stat-house.org/api/v2/ishure/user/${id}`)
      .then((response) => {
        console.log("change profile", response.data);
        setuser(response.data);
        setIsLoading(false); // Définir isLoading sur false une fois que les données sont chargées
      })
      .catch((error) => {
        console.error(error.message);
        setIsLoading(false); // Définir isLoading sur false en cas d'erreur de chargement des données
      });
  }, []);

  return (
    <>
      {/* 
<!-- Contact Start --> */}
      <div class="container-fluid py-5">
        <div class="container py-5">
          <div class="text-center mb-5">
            <h5
              class="text-primary text-uppercase mb-3"
              style={{ "letter-spacing": "5px;" }}
            >
              User Profile
            </h5>
            <h1>Profile</h1>
          </div>
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Chargement en cours...</span>
              </div>
            </div>
          ) : (
            <div class="row profile justify-content-center">
              <div class="col-md-8  ">
                <div class="profile-sidebar bg-secondary rounded">
                  {/* <!-- SIDEBAR USERPIC --> */}
                  <div class="profile-userpic row  justify-content-center">
                    <img
                      class="img-fluid border border-primary rounded-circle p-2"
                      src="/img/profile.png"
                      style={{ height: "120px", width: "120px" }}
                    />

                  </div>
                  {/* <!-- END SIDEBAR USERPIC -->
				<!-- SIDEBAR USER TITLE --> */}
                  <div class="profile-usertitle">
                    <h5 class="card-title mb-9 fw-semibold"></h5>
                    <div class="profile-usertitle-name">
                      Name : {user.name}
                    </div>
                    <div class="profile-usertitle-name">
                      Gender : {user.gender}
                    </div>
                    <div class="profile-usertitle-name">
                      Age : {user.age}
                    </div>
                    <div class="profile-usertitle-name">
                      Addresse : {user.state}
                    </div>
                    <div class="profile-usertitle-name">
                      Telephone : {user.telephone}
                    </div>
                    <div class="d-flex align-items-center justify-content-center ">

                      <div class="col-md-10 text-start p-3 bg-white rounded-xl ">
                        <div class="profile-usertitle-name">
                          1.Uri Mw'ishure ry'abarundi bose ? :<br /> -Inyishu : {user.question_1}
                        </div>
                        <div class="profile-usertitle-name">
                          2.Wipfuza kwifatanya n'abandi kugaruza umunezero ? : <br /> -Inyishu :  {user.question_1_1}
                        </div>
                        <div class="profile-usertitle-name">
                          3.Uvyemeye bivuye kumutima ?  : <br /> -Inyishu :  {user.question_2}
                        </div>
                        <div class="profile-usertitle-name">
                          4.Wipfuzako uburundi bugira amahoro bukaba impetso ya Bose ?:<br /> -Inyishu :  {user.question_3}
                        </div>
                        <div class="profile-usertitle-name">
                          5.Kugira uburundi bukire hoba ?: <br /> -Inyishu :  {user.question_4}
                        </div>
                        <div class="profile-usertitle-name">
                          6.Kugirango uburundi bukire bisabako abarundi bibabaza muguhagarika kugura cank GIKORESHA ibitera intege abansi b'uburundi Uzokwibabaza nawe  ? : <br /> -Inyishu : {user.question_5}
                        </div>
                        <div class="profile-usertitle-name">
                          7.Uratahura kuburundi bugizwe n'ubwoko bumwe bwabarundi ?: <br /> -Inyishu : {user.question_6}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- END SIDEBAR USER TITLE -->
				<!-- SIDEBAR BUTTONS --> */}
                  <div class="d-none profile-userbuttons mb-5 mt-5">
                    <Link
                      to="/"
                      type="button"
                      class="btn btn-primary py-2 px-4"
                    >
                      Disable User
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          )}

        </div>
      </div>
      {/* <!-- Contact End --> */}
      <div class="container"></div>
    </>
  );
}

export default ViewUser;
