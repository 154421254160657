import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ChangePass() {
  const history=useNavigate()
  const [Password_show, setPasswordShow] = useState(false);
  const [formData, setFormData] = useState({
    Ancien_password: "",
    Nouveau_password: "",
    Confirm_Password: "",
  });
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    toast.dismiss();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const validateForm = () => {
    console.log(formData);
    let isValid = true;

    const PASSWORD_REGEX =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;

    if (formData.Ancien_password.trim() === "") {
      toast.error("Votre Ancien Mot de Passe  est Obligatoire");
      isValid = false;
      return isValid;
    } 

    if (formData.Nouveau_password.trim() === "") {
      toast.error("Votre Nouveau Mot de Passe est Obligatoire");
      isValid = false;
      return isValid;
    } 

    if (formData.Confirm_Password.trim() === "") {
      toast.error("Confirme Votre Mot de Passe est Obligatoire");
      isValid = false;
      return isValid;
    } 

    if (formData.Confirm_Password !== formData.Nouveau_password) {
      toast.error(
        "Les Mot de Passe ne sont pas semblables.Verifier a Nouveau "
      );
      isValid = false;
      return isValid;
    }

    return isValid;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setLoading(true);
      try {
        // Envoi des données à l'API
        let user_id;
        if (localStorage.getItem("ishure-adx")) {
          user_id = localStorage.getItem("ishure-adx");
          console.log(user_id);
        }
        await axios.post("https://ishure-abarundi.webserver.control.stat-house.org/api/v2/ishure/admin/changepass", {
          id: user_id,
          password: formData.Ancien_password,
          newPassword: formData.Nouveau_password,
        });

        toast.success("Mot de Passe avec succès !");
        history("/Tableau_de_bord");
      } catch (error) {
        console.log(error);
        toast.error(error.response.data);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <>
      {/* 
    <!-- Contact Start --> */}
      <div class="container-fluid py-5">
        <div class="container py-5">
          <div class="text-center mb-5">
            <h5
              class="text-primary text-uppercase mb-3"
              style={{ "letter-spacing": "5px;" }}
            >
              Changer le Mot de Passe
            </h5>
            <h1>Mot de Passe</h1>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="contact-form bg-secondary rounded p-5">
                <div id="success"></div>
                <form
                  onSubmit={handleSubmit}
                  name="sentMessage"
                  id="contactForm"
                  novalidate="novalidate"
                >
                  <div class="control-group mb-3 d-flex align-items-center justify-center">
                    <input
                      type={Password_show ? "text" : "password"}
                      class="form-control border-0 p-4"
                      name="Ancien_password"
                      placeholder="Votre Ancien Password"
                      required="required"
                      value={formData.Ancien_password}
                      onChange={handleInputChange}
                    />
                    {!Password_show ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        id="togglePassword"
                        className=" bi bi-eye-end-fill ml-n4 end-3 cursor-pointer z-2 opacity-50"
                        viewBox="0 0 16 16"
                        onClick={() => setPasswordShow(!Password_show)}
                        style={{ right: 20 }}
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className=" bi bi-eye-end-fill ml-n4 end-3 cursor-pointer z-2 opacity-50"
                        id="mama"
                        viewBox="0 0 16 16"
                        style={{ right: 20 }}
                        onClick={() => setPasswordShow(!Password_show)}
                      >
                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                      </svg>
                    )}
                    <p class="help-block text-danger"></p>
                  </div>

                  <div class="control-group mb-3 d-flex align-items-center justify-center">
                    <input
                      type={Password_show ? "text" : "password"}
                      class="form-control border-0 p-4"
                      name="Nouveau_password"
                      value={formData.Nouveau_password}
                      onChange={handleInputChange}
                      placeholder="Votre Nouveau Mot de Passe"
                      required="required"
                    />
                    {!Password_show ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        id="togglePassword"
                        className=" bi bi-eye-end-fill ml-n4 end-3 cursor-pointer z-2 opacity-50"
                        viewBox="0 0 16 16"
                        onClick={() => setPasswordShow(!Password_show)}
                        style={{ right: 20 }}
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className=" bi bi-eye-end-fill ml-n4 end-3 cursor-pointer z-2 opacity-50"
                        id="mama"
                        viewBox="0 0 16 16"
                        style={{ right: 20 }}
                        onClick={() => setPasswordShow(!Password_show)}
                      >
                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                      </svg>
                    )}
                    <p class="help-block text-danger"></p>
                  </div>

                  <div class="control-group mb-3 d-flex align-items-center justify-center">
                    <input
                      type={Password_show ? "text" : "password"}
                      class="form-control border-0 p-4"
                      name="Confirm_Password"
                      value={formData.Confirm_Password}
                      onChange={handleInputChange}
                      placeholder="Confirmer Votre Mot de Passe"
                      required="required"
                    />
                    {!Password_show ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="gray"
                        id="togglePassword"
                        className=" bi bi-eye-end-fill ml-n4 end-3 cursor-pointer z-2 opacity-50"
                        viewBox="0 0 16 16"
                        onClick={() => setPasswordShow(!Password_show)}
                        style={{ right: 20 }}
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className=" bi bi-eye-end-fill ml-n4 end-3 cursor-pointer z-2 opacity-50"
                        id="mama"
                        viewBox="0 0 16 16"
                        style={{ right: 20 }}
                        onClick={() => setPasswordShow(!Password_show)}
                      >
                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                      </svg>
                    )}
                    <p class="help-block text-danger"></p>
                  </div>

                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn btn-primary py-2 px-4  signup"
                      disabled={loading}
                    >
                      {loading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        "Sauvegarder"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}
    </>
  );
}

export default ChangePass;
