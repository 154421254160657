import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import axios from 'axios';
import { toast } from 'react-toastify';
function Users() {
    const [Users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState(true);
    const [type, setType] = useState(true);

    useEffect(() => {
        setLoading(true);
        // Faites une requête GET à votre API pour récupérer les données paginées
        axios
            .get(`https://ishure-abarundi.webserver.control.stat-house.org/api/v2/ishure/user/getall?page=${page}&pageSize=${pageSize}`)
            .then((response) => {
                console.log(response.data)
                setUsers(response.data.Users);
                setTotalPages(response.data.totalPages);
            })
            .catch((error) => console.error('Erreur lors de la récupération des données:', error))
            .finally(() => setLoading(false));
    }, [page, pageSize]);

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };


    const handleConfirm = (idpost) => {
        setLoading(true);
        Swal.fire({
            title: "Etes-Vous Sur ?",
            text: "Supprimer un de ton post ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "No",
            reverseButtons: true,
            focusCancel: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`http://localhost:4000/post/delete/${idpost}`)
                    .then((response) => {
                        const updatedUsers = Users.filter((post) => post.id !== idpost);
                        setUsers(updatedUsers);
                        toast.success("post supprimé avec succès.")

                    })
                    .catch((error) => console.error('Erreur lors de la suppression de la post:', error))
                    .finally(() => setLoading(false));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                toast.success("vous avez annuler la suppression de la post.")
                setLoading(false)
            }
        });
    };

    function formatDate(isoDate) {
        const options = {
          weekday: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          month: "short",
          year: "numeric",
        };
        const date = new Date(isoDate);
        return date.toLocaleDateString(undefined, options);
      }
    return (
        <>
            <div class="container-fluid ">
                <div class="container  pb-3">
                    <div class="d-flex flex-row justify-content-between items-center text-center mb-5">

                        <div class="">
                            <h5
                                class="text-primary text-uppercase mb-3"
                                style={{ "letter-spacing": "5px" }}
                            >
                                Users Lists
                            </h5>
                        </div>
                        <div class="d-flex flex-row justify-content-center me-3 items-center">
                        
                        <div class="d-flex flex-row justify-content-center items-center">
                    
                        <div class="hidden">
                            <Link to={`/Post/New`} class="btn me-2 btn-primary">
                                Add New Post
                            </Link>
                        </div>
                        </div>
                        </div>
                    </div>

                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-12 d-flex ">
                            <div class="card w-100">
                                <div class="card-body p-4">
                                    <h5 class="card-title fw-semibold mb-4">Users</h5>
                                    <div class="table-responsive">
                                        {loading ? (
                                            <div className="text-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Chargement en cours...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <table class="table text-nowrap mb-0 align-middle">
                                                <thead class="text-dark fs-4">
                                                    <tr>
                                                        <th class="border-bottom-0">
                                                            <h6 class="fw-semibold mb-0">--</h6>
                                                        </th>
                                                        <th class="border-bottom-0">
                                                            <h6 class="fw-semibold mb-0">Username</h6>
                                                        </th>
                                                        <th class="border-bottom-0">
                                                            <h6 class="fw-semibold mb-0">Age</h6>
                                                        </th>
                                                        <th class="border-bottom-0">
                                                            <h6 class="fw-semibold mb-0">Address</h6>
                                                        </th>  <th class="border-bottom-0">
                                                            <h6 class="fw-semibold mb-0">Telephone</h6>
                                                        </th>
                                                        <th class="border-bottom-0">
                                                            <h6 class="fw-semibold mb-0">Join Date</h6>
                                                        </th>
                                                        <th class="border-bottom-0">
                                                            <h6 class="fw-semibold mb-0">Action</h6>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Users.map((user) => (
                                                        <tr key={user.id}>
                                                            <td class="border-bottom-0">
                                                                <img    src="img/profile.png" alt="" className="circle-img-25" />
                                                            </td>
                                                            <td class="border-bottom-0">
                                                                <h6 class="fw-semibold mb-1"> {user.name}</h6>
                                                            </td>
                                                            <td class="border-bottom-0">
                                                                <h6 class="fw-semibold mb-1"> {user.age}</h6>
                                                            </td>
                                                            <td class="border-bottom-0">
                                                                <h6 class="fw-semibold mb-1"> {user.state}</h6>
                                                            </td>
                                                            <td class="border-bottom-0">
                                                                <h6 class="fw-semibold mb-1"> {user.telephone}</h6>
                                                            </td>
                                                            
                                                            <td class="border-bottom-0">
                                                                <h6 class="fw-semibold mb-1">{formatDate(user.createdAt)}</h6>
                                                            </td>
                                                            
                                                            <td class="border-bottom-0">
                                                                <div class="d-flex  bg-white  ">
                                                                    <Link
                                                                        to={`/User/${user.id}`}
                                                                        class="btn btn-outline-primary mx-1  d-block"
                                                                    >
                                                                        View User
                                                                    </Link>

                                                                    <button
                                                                        onClick={() => handleConfirm(user.id)}
                                                                        class="btn btn-outline-primary mx-1  d-none"
                                                                    >
                                                                        Supprimer
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>



                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Users;

