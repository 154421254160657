import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/dist/sweetalert2.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Visitor/Component/Navbar/Navbar";
import Accueilcomponent from "./Visitor/Component/Accueil/Accueilcomponent";
import Footer from "./Visitor/Component/Footer/Footer";
import OrganisationComponent from "./Visitor/Component/Organisation/OrganisationComponent";
import MembreComponent from "./Visitor/Component/Membre/MembreComponent";
import ContactComponent from "./Visitor/Component/Contact/ContactComponent";
import Axe_Component from "./Visitor/Component/Axe_Recherche/Axe_Component";
import Unite_EssaieComponent from "./Visitor/Component/Unite_Essaie/Unite_EssaieComponent";
import ProjetComponent from "./Visitor/Component/Project/ProjetComponent";
import PublicationComponent from "./Visitor/Component/Publication/PublicationComponent";
import EvenementComponent from "./Visitor/Component/Evenement/EvenementComponent";
import EvenementDetailComponent from "./Visitor/Component/Evenement/EvenementDetailComponent";
import AproposComponent from "./Visitor/Component/A_propos/AproposComponent";
import PublicationDetailComponent from "./Visitor/Component/Publication/PublicationDetailComponent";
import ProjetDetailComponent from "./Visitor/Component/Project/ProjetDetailComponent";
import LoginComponent from "./Visitor/Component/Auth/LoginComponent";
import AdminLoginComponent from "./Visitor/Component/Auth/AdminLoginComponent";
import CooperationInternationale from "./Visitor/Component/Cooperation/CooperationInternationale";

import AdminNavbar from "./Admin/Component/Navbar/AdminNavbar";
import Sidebar from "./Admin/Component/Sidebar/Sidebar";
import Tableau_de_Bord from "./Admin/Component/Tableau_de_Bord/Tableau_de_Bord";
import AdminFooter from "./Admin/Component/Footer/AdminFooter";
import Publication from "./Admin/Component/Publication/Publication";
import EditerPublication from "./Admin/Component/Publication/EditerPublication";
import NouveauBlog from "./Admin/Component/Publication/NouveauBlog";
import Evenement from "./Admin/Component/Evenement/Evenement";
import EditerEvenement from "./Admin/Component/Evenement/EditerEvenement";
import NouveauEvenement from "./Admin/Component/Evenement/NouveauEvenement";
import Axe_Recherche from "./Admin/Component/Axe-Recherche/Axe_Recherche";
import EditerAxe_Recherche from "./Admin/Component/Axe-Recherche/EditerAxe_Recherche";
import NouveauAxe_Recherche from "./Admin/Component/Axe-Recherche/NouveauAxe_Recherche";
import Cooperation from "./Admin/Component/Cooperation/Cooperation";
import EditerCooperation from "./Admin/Component/Cooperation/EditerCooperation";
import NouveauCooperation from "./Admin/Component/Cooperation/NouveauCooperation";
import Labo from "./Admin/Component/Labo/Labo";
import EditerLabo from "./Admin/Component/Labo/EditerLabo";
import NouveauLabo from "./Admin/Component/Labo/NouveauLabo";
import NewPost from "./Admin/Component/Posts/NewPost";
import NouveauChercheur from "./Admin/Component/Chercheur/NouveauChercheur";
import EduterChercheur from "./Admin/Component/Chercheur/EduterChercheur";
import Equipe from "./Admin/Component/Equipe/Equipe";
import NouveauEquipe from "./Admin/Component/Equipe/NouveauEquipe";
import EduterEquipe from "./Admin/Component/Equipe/EduterEquipe";
import Ajouter_Chercheur from "./Admin/Component/Axe-Recherche/Ajouter_Chercheur";
import Posts from "./Admin/Component/Posts/Post";
import NewPostContent from "./Admin/Component/Posts/NewPostContent";
import Profile from "./Admin/Component/Profile/Profile";
import ChangePassword from "./Admin/Component/Profile/ChangePassword--";
import ChangeProfile from "./Admin/Component/Profile/ChangeProfile";
import Viewpost from "./Admin/Component/Posts/ViewPost";
import ViewUser from "./Admin/Component/Users/ViewUser";
import UserAdminNavbar from "./User/Component/Navbar/AdminNavbar";
import UserSidebar from "./User/Component/Sidebar/Sidebar";
import UserTableau_de_Bord from "./User/Component/Tableau_de_Bord/Tableau_de_Bord";
import UserAdminFooter from "./User/Component/Footer/AdminFooter";
import UserPublication from "./User/Component/Publication/Publication";
import UserEditerPublication from "./User/Component/Publication/EditerPublication";
import UserNouveauPublication from "./User/Component/Publication/NouveauPublication";
import UserEvenement from "./User/Component/Evenement/Evenement";
import UserEditerEvenement from "./User/Component/Evenement/EditerEvenement";
import UserNouveauEvenement from "./User/Component/Evenement/NouveauEvenement";
import UserAxe_Recherche from "./User/Component/Axe-Recherche/Axe_Recherche";
import UserEditerAxe_Recherche from "./User/Component/Axe-Recherche/EditerAxe_Recherche";
import UserNouveauAxe_Recherche from "./User/Component/Axe-Recherche/NouveauAxe_Recherche";
import UserCooperation from "./User/Component/Cooperation/Cooperation";
import UserEditerCooperation from "./User/Component/Cooperation/EditerCooperation";
import UserNouveauCooperation from "./User/Component/Cooperation/NouveauCooperation";
import UserLabo from "./User/Component/Labo/Labo";
import UserEditerLabo from "./User/Component/Labo/EditerLabo";
import UserNouveauLabo from "./User/Component/Labo/NouveauLabo";
import UserProfile from "./User/Component/Profile/Profile";
import UserChangePassword from "./User/Component/Profile/ChangePassword";
import UserChangeProfile from "./User/Component/Profile/ChangeProfile";
import { encryptData, decryptData } from "./encryptionModule";
import Users from "./Admin/Component/Users/Users";
import ChangePass from "./Admin/Component/Profile/ChangePass";

function App() {
  const [isAdmin, setAdmin] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("ishure-adx")) {
      const userRole = localStorage.getItem("ishure-adx");
      setAdmin(true);
      
    }
    else {
      setAdmin(false);
      
    }
  }, []);

  return isAdmin ?
    <> 
     <BrowserRouter>
        <div
          class="page-wrapper"
          id="main-wrapper"
          data-layout="vertical"    
          data-navbarbg="skin6"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
        >
          <Sidebar />
          {/* <!--  Main wrapper --> */}
          <div class="body-wrapper">
            <AdminNavbar />
            <Routes>
              <Route path="/Tableau_de_bord" element={<Tableau_de_Bord />} />
              <Route path="/" element={<Tableau_de_Bord />} />
              <Route path="/Publication" element={<Publication />} />
              <Route
                path="/Publication/Editer/:id"
                element={<EditerPublication />}
              />
              <Route
                path="/Post/:id"
                element={<Viewpost />}
              />
              <Route
                path="/User/:id"
                element={<ViewUser />}
              />
              <Route path="/Blog/Nouveau" element={<NouveauBlog />} />
              <Route path="/Evenement" element={<Evenement />} />
              <Route
                path="/Evenement/Editer/:id"
                element={<EditerEvenement />}
              />
              <Route path="/Evenement/Nouveau" element={<NouveauEvenement />} />
              <Route path="/Recherches" element={<Axe_Recherche />} />
              <Route
                path="/Ajouter_Chercheur"
                element={<Ajouter_Chercheur />}
              />
              <Route
                path="/Axe_de_Recherche/Editer/:id"
                element={<EditerAxe_Recherche />}
              />
              <Route
                path="/Axe_de_Recherche/Nouveau"
                element={<NouveauAxe_Recherche />}
              />
              <Route path="/Cooperation" element={<Cooperation />} />
              <Route
                path="/Cooperation/Editer/:id"
                element={<EditerCooperation />}
              />
              <Route
                path="/Cooperation/Nouveau"
                element={<NouveauCooperation />}
              />
              <Route path="/Laboratoire" element={<Labo />} />
              <Route path="/Laboratoire/Editer/:id" element={<EditerLabo />} />
              <Route path="/Laboratoire/Nouveau" element={<NouveauLabo />} />
              <Route path="/Equipe" element={<Equipe />} />
              <Route path="/Equipe/Nouveau" element={<NouveauEquipe />} />
              <Route path="/Equipe/Editer/:id" element={<EduterEquipe />} />

              <Route path="/Organisation" />
              <Route path="/Change_Password" element={<ChangePass />} />
              <Route path="/users" element={<Users />} />
              <Route path="/posts" element={<Posts />} />
              <Route path="/Post/New" element={<NewPost/>} />
              <Route path="/PostContent/New/:lang/:id" element={<NewPostContent/>} />
              <Route
                path="/Chercheur/Editer/:id"
                element={<EduterChercheur />}
              />
              <Route path="/Chercheur/Nouveau" element={<NouveauChercheur />} />
              <Route path="/Change_Mot_de_Passe" />
              <Route path="*" />
            </Routes>
            <AdminFooter />
          </div>
        </div>
        <ToastContainer position="top-center" />
      </BrowserRouter>

    </>
    :
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <LoginComponent
                setAdmin={setAdmin}
              />
            }
          />
          <Route path="*" />
        </Routes>
       
        <ToastContainer position="top-center" />
      </BrowserRouter>
    </>

}

export default App;
