import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const categories = [
    { value: 'News', label: 'News' },
    { value: 'Politics', label: 'Politics' },
    { value: 'Economy', label: 'Economy' },
    { value: 'Culture', label: 'Culture' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Health', label: 'Health' },
    { value: 'Environment', label: 'Environment' },
    { value: 'International', label: 'International' },
    { value: 'Education', label: 'Education' },
    { value: 'Lifestyle', label: 'Lifestyle' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Opinion', label: 'Opinion' },
    { value: 'Business', label: 'Business' },
    { value: 'Science', label: 'Science' },
    { value: 'Travel', label: 'Travel' },
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Food', label: 'Food' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Automotive', label: 'Automotive' },
    { value: 'History', label: 'History' },
    { value: 'Crime', label: 'Crime' },
    { value: 'Technology Reviews', label: 'Technology Reviews' },
    { value: 'Interviews', label: 'Interviews' },
    { value: 'Human Interest', label: 'Human Interest' },
    { value: 'Local News', label: 'Local News' },
    { value: 'Opinion Polls', label: 'Opinion Polls' },
    { value: 'Editorials', label: 'Editorials' },
    { value: 'Community', label: 'Community' },
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Podcasts', label: 'Podcasts' },
];

const grades = [
    { value: 'Prof', label: 'Prof' },
    { value: 'Dr Ir', label: 'Dr Ir' },
    { value: 'Dr', label: 'Dr' },
    { value: 'MrScIr', label: 'MrScIr' },
    { value: 'Mr', label: 'Mr' },
    { value: 'Ir', label: 'Ir' },
    { value: 'Mme', label: 'Mme' },
];

function NewPost() {
    let author_id=1;
    const [PostName, setPostName] = useState('');
    const [categorie, setCategorie] = useState(null);
    const [loading, setLoading] = useState(false);
    const history=useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validez les champs un par un
        if (!PostName) {
            toast.error('Veuillez saisir un nom.');
            return;
        }

        if (!categorie) {
            toast.error('Veuillez sélectionner un grade.');
            return;
        }
        // Si toutes les validations sont passées, continuez ici
        setLoading(true);

        try {
            // Envoi des données à l'API
            const formData = new FormData();
            formData.append('post_name', PostName);
            formData.append('genre', categorie.value);
            formData.append('author_id', author_id);
            formData.append('short_url', "short.url/example");
            console.log(formData)

            const data={
                "authorId": author_id,
                "genre": categorie.value,
                "short_url": "short.url/example",
                "post_name": PostName
              }
            await axios.post('https://ishure-abarundi.webserver.control.stat-house.org/api/v2/ishure/newpost', data);
            toast.success('Formulaire soumis avec succès !');
            history("/Posts")
            setPostName('');
            setCategorie(null);
        } catch (error) {
            toast.error('Une erreur s\'est produite lors de la soumission du formulaire.');
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title fw-semibold mb-4">
                            Add New Post
                        </h5>
                        <div class="card">
                            <div class="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="nom" className="form-label">
                                            Post Name
                                        </label>
                                        <input
                                            type="text"
                                            id="PostName"
                                            placeholder="Write Your Post Name"
                                            className="form-control"
                                            value={PostName}
                                            onChange={(e) => setPostName(e.target.value)}
                                        />
                                    </div>

                                 

                                    <div className="mb-3">
                                        <label htmlFor="categorie" className="form-label">
                                            Genre
                                        </label>
                                        <Select
                                            id="categorie"
                                            options={categories}
                                            value={categorie}
                                            onChange={setCategorie}
                                        />
                                    </div>


                                    <button type="submit" className="btn btn-primary float-end" disabled={loading}>
                                        {loading ? <i className="fas fa-spinner fa-spin"></i> : 'Enregistrer'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewPost;
